import React from 'react'
import './blogdetail.css';
import { Article } from '../../comp';
import {blog01,blog001,blog0001, blog02, blog03, blog04, blog05, blog002, blog003, blog004, blog005,blog008,blog009} from './imports';
import { Link } from 'react-router-dom';
const BlogDetail = () => {
  return (
    <div className='gpt3__blog section_padding' id='servicos'>
      <div className='gpt3__blog-heading'>
        <h1 className='gradient__text'>
        Uma ideia um produto, 
        Nós desenvolvemos.
        </h1>
      </div>
      <div className='gpt3__blog-container'>
        

        <div className='gpt3__blog-container_groupB'>
          <Link to="/inogest"><Article imageUrl={blog0001} date="InoGest" title="InoGest sistema de gestão desenvolvido para impulsionar a gestão de pequenas e médias empresas, focada na simplicidade de gestão."/></Link>
          <Link to="/inogestatas"><Article imageUrl={blog005} date="InoGest Atas" title="O InoGest Atas é um sistema de gestão de atas de reunião, que visa impulsionar o planeamento,organização e gestão das decisões tomadas em reuniões."/> </Link>
          <Link to="/inogestdisponibilidade"><Article imageUrl={blog0001} date="Inogest Disponibilidade" title="O InoGest Disponibilidade é um sistema de gestão CMMS qué facilita a equipa de manutenção de qualquer organização."/> </Link>
          <Link to="/simplify"><Article imageUrl={blog005} date="Simplify" title="Simplify criada com objetivo de simplificar, inovar e tornar transparente a gestão dos contratos e acordos á nível de serviços. Podendo ser utilizado em todos ambientes corporativos."/> </Link>
          <Link to="/baraquita"><Article imageUrl={blog004} date="Baraquita" title="BaraQuita criada com objetivo de simplificar, inovar e tornar transparente a gestão das atividades dos pequenos comerciantes, barracas, negócios de encomendas, takeaways, mercearias e restaurantes."/> </Link>
          <Link to="/fomento"><Article imageUrl={blog005} date="Fomento" title="O InoGest Fomento é um sistema de gestão de atividades de fomento na área de agricultura."/> </Link>
          <Link to="/inosecure"><Article imageUrl={blog009} date="InoSecure" title="O InoSecure é sistema de gestão e controlo de segurança, que permite controlo de entradas e saídas de um estabelecimento e emissão de procedimentos."/> </Link>
          <Link to="/inotreina"><Article imageUrl={blog008} date="InoTreina" title="O InoTreina é plataforma criada para impulsionar o aprendizado, onde são disponibilizados cursos de curta duração com emissão de certificados."/> </Link>
          <Link to="/clickclick"><Article imageUrl={blog003} date="Plataforma ClickClick" title="A plataforma mobile focada em soluções de mobilidade urbana. Incluí o ClickTuk focada na mobilidade de transportadores de Motociclo e ClickTáxi no transporte de táxis."/> </Link>
          <Link to="/edcmz"><Article imageUrl={blog002} date="EDC MZ" title="Exames da escola de condução, impulsionador de resultado no aprendizado teórico de carta de condução."/> </Link>
         
         
        </div>
      </div>
    </div>
  )
}

export default BlogDetail
