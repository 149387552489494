import React from 'react';
import {Link } from "react-router-dom";
import './footer.css';
import gpt3Logo from '../../assets/inovatislogo.svg';
import {FaFacebook, FaInstagram, FaUser, FaList} from 'react-icons/fa';

function Footer (){
    return (
        <footer>
          <div className='gpt3__footer section_padding'>
      <div className='gpt3__footer-heading'>
        <h1 className='gradient__text'>
          INOVATIS MZ LTD
        </h1>
      </div>
        {/* <div className='gpt3__footer-btn'>
           <p>Request Early Access</p> 
        </div> */}

        <div className='gpt3__footer-links'>
          <div className='gpt3__footer-links_logo'>
            <img src={gpt3Logo} alt="logo" />
            <p>Africa, Moçambique, Todos Direitos Reservados</p>
          </div>

          <div className='gpt3__footer-links_div'>
            <h4>Links</h4>
            <Link to='/company'><FaUser/> Sobre nós</Link>
            <a href="https://facebook.com/inovatis"><FaFacebook/> Facebook</a>
            <a href="https://instagram.com/inovatis"><FaInstagram/> Instagram</a>
            
          </div>

          <div className='gpt3__footer-links_div'>
            <h4>Company</h4>
            
            <p><a href="privacy.php"><FaList/> Políticas e Privacidade</a> </p>
            {/* <p>Contactos</p> */}
          </div>

          <div className='gpt3__footer-links_div'>
            <h4>Entre em contacto</h4>
            <p>Africa, Moçambique</p>
            <p>+258 85 0110 300</p>
            <p>apoio@inovatis.co.mz</p>
          </div>
        </div>

        <div className='gpt3__footer-copyright'>
          <p>© 2022 INOVATIS MZ LTD. Todos Direiros Reservados.</p>
        </div>

      
    </div>
        </footer>

    )

}

export default Footer