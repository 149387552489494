import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home';
import Company from './components/pages/Company';
import Contact from './components/pages/Contact';
import Project from './components/pages/Project';
import Inogest from './components/pages/Inogest';
import NewProject from './components/pages/NewProject';
import Container from "./components/layout/Container";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Baraquita from "./components/pages/Baraquita";
import ClickClick from "./components/pages/ClickClick";
import EdcMz from "./components/pages/EdcMz";
import Fomento from "./components/pages/Fomento";
import InogestAtas from "./components/pages/InogestAtas";
import InogestDisponibilidade from "./components/pages/InogestDisponibilidade";
import InoSecure from "./components/pages/InoSecure";
import InoTreina from "./components/pages/InoTreina";
import Simplify from "./components/pages/Simplify";
function App() {
  return (
      <Router>

          <Navbar/>
          <Container customClass="min-height">
          <Routes>
           
              <Route exact path='/' element={<Home/>} />
              <Route exact path='/company' element={<Company/>} />
              <Route exact path='/project' element={<Project/>} />
              <Route exact path='/contacts' element={<Contact/>} />
              <Route exact path='/newproject' element={<NewProject/>} />

              <Route exact path='/inogest' element={<Inogest/>} />
              <Route exact path='/simplify' element={<Simplify/>} />
              <Route exact path='/baraquita' element={<Baraquita/>} />
              <Route exact path='/clickclick' element={<ClickClick/>} />
              <Route exact path='/edcmz' element={<EdcMz/>} />
              <Route exact path='/fomento' element={<Fomento/>} />
              <Route exact path='/inogestatas' element={<InogestAtas/>} />
              <Route exact path='/inogestdisponibilidade' element={<InogestDisponibilidade/>} />
              <Route exact path='/inosecure' element={<InoSecure/>} />
              <Route exact path='/inotreina' element={<InoTreina/>} />
            
          </Routes>
          </Container>

         <Footer/>

      </Router>
  );
}

export default App;
