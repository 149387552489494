import React from 'react'
import './blog.css';
import { Article } from '../../comp';
import {blog01,blog001, blog02, blog03, blog04, blog05, blog002, blog003, blog004, blog005} from './imports';
import { Link } from 'react-router-dom';
const Blog = () => {
  return (
    <div className='gpt3__blog section_padding' id='servicos'>
      <div className='gpt3__blog-heading'>
        <h1 className='gradient__text'>
        Uma ideia um produto, 
        Nós desenvolvemos.
        </h1>
        <Link to="/project">
          <div className='gpt3__cta-btn'>
          <button>Ver todos produtos</button>
          </div>
        </Link>
      </div>
      <div className='gpt3__blog-container'>
        <div className='gpt3__blog-container_groupA'>
        <Link to="/inogest"><Article imageUrl={blog001}  date="InoGest" title="InoGest sistema de gestão desenvolvido para impulsionar a gestão de pequenas e médias empresas, focada na simplicidade de gestão. "/></Link>
        </div>

        <div className='gpt3__blog-container_groupB'>
          <Link to="/edcmz"> <Article imageUrl={blog002} date="EDC MZ" title="Exames da escola de condução, impulsionador de resultado no aprendizado teórico de carta de condução."/></Link>
          <Link to="/clickclick"><Article imageUrl={blog003} date="Plataforma ClickClick" title="A plataforma mobile focada em soluções de mobilidade urbana. Incluí o ClickTuk focada na mobilidade de transportadores de Motociclo e ClickTáxi no transporte de táxis."/></Link>
          <Link to="/baraquita"><Article imageUrl={blog004} date="Baraquita" title="BaraQuita criada com objetivo de simplificar, inovar e tornar transparente a gestão das atividades dos pequenos comerciantes, barracas, negócios de encomendas, takeaways, mercearias e restaurantes."/></Link>
          <Link to="/simplify"><Article imageUrl={blog005} date="Simplify" title="Simplify criada com objetivo de simplificar, inovar e tornar transparente a gestão dos contratos e acordos á nível de serviços. Podendo ser utilizado em todos ambientes corporativos."/></Link>
        </div>
      </div>
    </div>
  )
}

export default Blog
