import {Link } from "react-router-dom";
import React,{useState} from 'react';
import Container from "./Container";
import styles from './Navbar.module.css';
import {RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import logo from '../../assets/inovatislogo.svg';
import '../../App.css';


const Menu = () => (
    <>
            <p><Link to='/'>Inicio</Link></p>
            <p><Link to='/company'>Empresa</Link></p>
            {/* <p><a href="#servicos">Serviços</a></p> */}
            <p><Link to='/project'>Projetos</Link></p>
            
            {/* <p><a href="#blog">Inotreina</a></p> */}
    </>
  )

function Navbar(){
    const [toggleMenu, setToggleMenu] = useState(false);
    return(
        <div className='gpt3__navbar gradient__bg'>
        <div className='gpt3__navbar-links'>
          <div className='gpt3__navbar-links_logo'>
            <img src={logo} alt="logo" />
          </div>
  
          <div className='gpt3__navbar-links_container'>
            <Menu/>
          </div>
  
          
        </div>
  
        <div className='gpt3__navbar-sign'>
            {/* <p>Contactos</p> */}
            <Link to='/contacts'><button type='button'>Contactos</button></Link>
          </div>
  
          <div className='gpt3__navbar-menu'>
            {toggleMenu ? <RiCloseLine color="#fff" size={27} onClick={()=>setToggleMenu(false)}/>
            : <RiMenu3Line color="#fff" size={27} onClick={()=>setToggleMenu(true)}/>
            }
            {toggleMenu && (
              <div className='gpt3__navbar-menu_container scale-up-center'>
                <div className='gpt3__navbar-menu_container-links'>
                <Menu/>
                <div className='gpt3__navbar-menu_container-links-sign'>
                  {/* <p>Sign in</p> */}
                  <Link to='/contacts'><button type='button'>Contactos</button></Link>
                </div>
                </div>
              </div>
            )}
          </div>
      </div>
    )
}

export default Navbar