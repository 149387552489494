import {Blog,Features,Footer,Header,Possibility,WhatGPT3} from '../container';
function InoTreina(){
    return (
        <div className='App'>
      
        InoTreina
        </div>
    )
}

export default InoTreina


