import React from 'react';
import './brand.css';
import { google, slack,brandedc,brandeds, atlassian, dropbox, shopify, brandinogest,brandclick} from './imports';

const Brand = () => {
  return (
    <div className='gpt3__brand section__padding'>
      <div>
        <img src={brandinogest} alt='inogest' />
      </div>

      <div>
        <img src={brandedc} alt='edc' />
      </div>

      <div>
        <img src={brandeds} alt='eds' />
      </div>

      <div>
        <img src={brandclick} alt='click' />
      </div>

      {/* <div>
        <img src={shopify} alt='shopify' />
      </div> */}


    </div>
  )
}

export default Brand
