import {Blog,FeaturesContact,Footer,Header,Possibility,WhatGPT3} from '../container';
function Contact(){
    return (
        <div className='App'>
      
        <FeaturesContact/>
        </div>
    )
}

export default Contact