import {Blog,Features,Footer,Header,Possibility,WhatGPT3} from '../container';
function Simplify(){
    return (
        <div className='App'>
      
        <div className="container">
            <div className="header">
                <h1>Simplificando a Gestão</h1>
            </div>
            <div className="body">
                <p>Simplify criada com objetivo de simplificar, inovar e tornar transparente a gestão das actividades dos pequenos transportadores, Táxi-mota, Txopelas, Táxi, Carrinhas de Alúguer e Pequenos transportadores de longo curso. Os proprietários do negócio, dispondo de uma ferramenta de gestão, disponível em tempo real, isto é, podendo ser efectuado os registos das receitas e custos no sistema, onde o proprietário e os motoristas, possam ambos visualizar as principais actividades do seu negócio. A INOVATIS MZ entendendo os grandes desafios da comunidade Moçambicana, disponibiliza o sistema Simplify, de forma simplificada, isto é, focado nos resultados da actividade dos transportadores, facilitando a gestão das suas actividades.</p>
            </div>
        </div>
        </div>
    )
}

export default Simplify
