import './local.css';
import { Article } from '../comp';
import {blog01,blog001,blog0001, blog02, blog03, blog04, blog05, blog002, blog003, blog004, blog005,blog008,blog009} from './../../components/container/blogdetail/imports';
function Baraquita(){
    return (
        <div className='App'>
      
      <div className="container">
                <div className='header'>
                    <h1>BaraQuita</h1>
                </div>
                <div className="body">
                    <p>BaraQuita criada com objetivo de simplificar, inovar e tornar transparente a gestão das atividades dos pequenos comerciantes, barracas, negócios de encomendas, takeaways, mercearias e restaurantes. Os proprietários do negócio, dispondo de uma ferramenta de gestão, disponível em tempo real, isto é, podendo ser efectuado os registos das despesas, vendas, controlo do stock, registar os produtos, actualizar os preços dos produtos e monitorar os seus resultados no painel de indicadores.</p> 
                    <p>A INOVATIS MZ entendendo os grandes desafios da comunidade Moçambicana, disponibiliza o sistema BaraQuita, de forma simplificada, isto é, focado nos resultados da actividade facilitando a gestão.: </p>
                </div>
                <div className='header'>
                    <h1>Como funciona a BaraQuita</h1>
                </div>

                <div className="body">
                <p>A BaraQuita um sistema web, com objectivo de sistematizar a gestão dos pequenos comerciantes, sem orçamentos astronômicos para ter um sistema de gestão similar das grandes organizações.

                    A BaraQuita torna possível a sistematização das actividades dos pequenos comerciantes, onde pode ter o registro das suas compras, vendas, stock e seus lucros numa plataforma de fácil uso.

                    A BaraQuita permite um registro de dados sem limites, imagina aquele seu bloco de nota que está exposto a riscos como, perda, má conservação, acaba e tem que começar outro, sendo seus dados repartidos e sem capacidade de cruzamento entre eles. A BaraQuita é seu sistema que lhe informará se seu negócio vale a pena ou não.</p>

                <p><strong> Torna-se um Gestor (a) em pequenas etapas com o sistema BaraQuita:</strong></p>
                <p>1- Insira um produto novo na seção produto, atribuindo o nome e sua descrição, sendo a descrição a que melhor se enquadra ao seu negócio; <br />

                    Exemplo1: Nome=Tomate; Descrição=Vegetais; <br />

                    Exemplo2: Nome=Coca cola; Descrição=Bebida não alcoólica;</p>
                
                <p>2- Adiciona stock do produto definido, onde deve inserir a quantidade do produto disponível para venda, adicionar o preço de compra ou o custo para produzir o produto e por fim o preço que o produto será vendido.</p>


                <p>Exemplo1: Quantidade de tomate igual a 50 quilos; preço de compra de cada quilo 40 meticais; preço de venda de cada quilo 55 meticais; <br />

                Exemplo2: Quantidade de Bolos de chocolate igual a 3 unidades; custo para fazer o bolo 550 meticais; preço de venda do bolo 850 meticais;</p>

                <p>3- O BaraQuita e gestão simplificada, insira sua com 3 cliques.</p>
                <p>Exemplo: As vendas só poderão ser registradas de itens disponíveis em stock, deste modo deve, clicar no vendas, selecionar o produto, quantidade vendida e salvar.</p>

                <p>4- Como todo o negócio o seu não será diferente, existem custos e esses deve ser registados e analisados.</p>
                <p>Exemplo: Na seção despesas, inserir nova despesa, definindo o valor da despesa (pagamento da licença diária da banca, compra de energia, compra de embalagens, pagamento de salários, etc), estas podem ser de várias ordem de modo a dar visibilidade aos custos relacionados com o seu negócio.</p>

                {/* <div className='gpt3__blog-container_groupB'>
                    <Article imageUrl={blog0001} date="InoGest" title="InoGest sistema de gestão desenvolvido para impulsionar a gestão de pequenas e médias empresas, focada na simplicidade de gestão."/>
                    <Article imageUrl={blog0001} date="InoGest" title="InoGest sistema de gestão desenvolvido para impulsionar a gestão de pequenas e médias empresas, focada na simplicidade de gestão."/>
                    <Article imageUrl={blog0001} date="InoGest" title="InoGest sistema de gestão desenvolvido para impulsionar a gestão de pequenas e médias empresas, focada na simplicidade de gestão."/>
                </div> */}
                </div>


                
                

            </div>
        </div>
    )
}

export default Baraquita


