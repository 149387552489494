import React from 'react';
import './cta.css';

const CTA = () => {
  return (
    <div className='gpt3__cta'>
      <div className='gpt3__cta-content'>
        <p>Entre em contacto conosco para saber mais!</p>
        <h1>Envie-nos um email e fique a par de todas novidades.</h1>
      </div>
      <div className='gpt3__cta-btn'>
        <button>Iniciar</button>
      </div>
    </div>
  )
}

export default CTA
