import React from 'react';
import './features.css';
import { Feature } from '../../comp/';

const featuresData = [
    {
      title: 'Missão',
      text: 'Desenvolver softwares e aplicativos sustentáveis e de fácil uso.'
    },
    {
      title: 'Visão',
      text: 'Ser referência em desenvolvimento de softwares e aplicativos inovadores, impulsionando o desenvolvimento dos nossos clientes.'
    },
    {
      title: 'Valores',
      text: 'Softwares e aplicativos amigos do ambiente. Softwares e aplicativos permitindo mobilidade.'
    },
   

   
];
const Features = () => {
  return (
    <div className='gpt3__features section_padding' id='features'>
      <div className='gpt3__features-heading'>
        <h1 className='gradient__text'>
         INOVATIS MZ LTD
        </h1>
        <p className='paragraph'>Impulsionando o desenvolvimento</p>
      </div>

      <div className='gpt3__features-container'>
        {featuresData.map((item,index)=>(
          <Feature
            title={item.title} 
            text = {item.text}
            key={item.title+item.text}
          />
        ))}
      </div>
    </div>
  )
}

export default Features
