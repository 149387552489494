import {Blog,Features,Footer,Header,Possibility,WhatGPT3} from '../container';
import './local.css';
function Inogest(){
    return (
        <div className='App'>
            <div className="container">
                <div className='header'>
                    <h1>InoGest</h1>
                </div>
                <div className="body">
                    <p>InoGest é um sistema de gestão desenvolvido para impulsionar a gestão de pequenas e médias empresas, focada na simplicidade de gestão.</p> 
                    <p>O InoGest possuí a seguintes funcionalidades: </p>

                    <ul>
                        <p><li>Recursos humanos: Recursos humanos é o activo mais importantes de uma empresa, para tal este activo deve ser gerido de forma eficaz, InoGest traz de forma Simples e Inovadora gestão de recursos humanos. </li></p>
                        <p><li>Finanças: Finanças a razão das empresas continuarem no mercado, ao custo certo e com o retorno do investimento envolvido no negócio. Esta tarefa só é possível ser alcançada de forma Eficiente com o uso da InoGest. </li></p> 
                        <p><li>Painel de indicadores: A InoGest focada na simplicidade e com objetivo de impulsionar a gestão dos nossos clientes, traz de forma compacta no Painel de Indicadores os resultados importantes da empresa, permitindo um acompanhamento integrado das atividades. </li></p> 
                        <p><li>Relatórios: A InoGest e Inovar a Gestão, com apenas pequenas etapas apresenta principais relatórios da empresa. </li></p> 
                        <p><li>Personalização: InoGest apresentada de forma modular, permitindo a personalização de acordo com o processo empresarial do nosso cliente. O nosso foco é a performance do nosso cliente, InoGest permite ajustar o sistema aos processos.</li></p> 

                    </ul>
                </div>
                

            </div>
            
        
        </div>
    )
}


export default Inogest