import {BlogDetail,Features,Footer,Header,Possibility,WhatGPT3} from '../container';
function Project(){
    return (
        <div className='App'>
      
        <BlogDetail/>
        </div>
    )
}

export default Project