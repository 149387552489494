import {Blog,Features,Footer,Header,Possibility,WhatGPT3} from '../container';
function InogestAtas(){
    return (
        <div className='App'>
      
      <div className="container">
                <div className='header'>
                    <h1>InoGest Atas</h1>
                </div>
                <div className="body">
                    
                    <p>O sistema InoGest ATAs, tem como objetivo dinamizar a gestão de atas geradas em reuniões, tornando mais eficientes a gestão de tomada de decisão efetuadas nas reuniões.</p> 
                    <p>O sistema InoGest ATAs, permite uma sistemática de: </p>
                    <ul>
                        <p><li>Elaboração das atas.</li></p>
                        <p><li>Divulgação</li></p>
                        <p><li>Monitoramento das decisões tomadas.</li></p>
                        <p><li>Uma base de dados organizada, permitindo o acesso a qualquer momento e lugar das atas geradas.</li></p>
                    </ul>
                </div>
                

            </div>
        </div>
    )
}

export default InogestAtas
