import React from 'react';
import { Feature } from '../../comp/';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className='gpt3__whatgpt3 section_margin' id='empresa'>
      <div className='gpt3__whatgpt3-feature'>
        <Feature title="Nosso propósito" text="Uma ideia, um produto. Estamos focados na qualidade e eficiência, produzindo de softwares e aplicativos de fácil uso."/>
      </div>

      <div className='gpt3__whatgpt3-heading'>
        <h1 className='gradient__text'>Uma ideia um produto</h1>
        <p>Explorar nossos produtos</p>
      </div>

      <div className='gpt3__whatgpt3-container'>
        <Feature title="Visão" text="Ser referência em desenvolvimento de softwares e aplicativos inovadores, impulsionando o desenvolvimento dos nossos clientes.. "/>
        <Feature title="Missão" text="Desenvolver softwares e aplicativos sustentáveis e de fácil uso."/>
        <Feature title="Valores" text="Softwares e aplicativos amigos do ambiente. Softwares e aplicativos permitindo mobilidade."/>
      </div>
    </div>
  )
}

export default WhatGPT3
