
import styles from './Home.module.css'
import savings from '../../img/savings.svg';
import LinkButton from '../layout/LinkButton';
import {Article, Brand, CTA, Feature, Navbar} from '../comp';
import {Blog,Features,Footer,Header,Possibility,WhatGPT3} from '../container';
function Home(){
    return (
        <div className='App'>
             <div className='gradient__bg'>
                
             <Header/>
            </div>
            <Brand/>
            <WhatGPT3/>
            <CTA/>
            <Blog/>
            <Features/>
        
        </div>
    )
}

export default Home